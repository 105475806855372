<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        class="d-flex align-center justify-center pa-8"
      >
        <v-card
          class="main-card"
          width="100%"
          height="100%"
          elevation="2"
        >
          <div>
            <div class="total-license pa-5">
              Total Licenses
            </div>
            <div class="d-flex justify-space-between">
              <div class="main-card-content pl-6">
                {{ digiBoardLicenceCounts.total_licenses }}
              </div>
              <v-card-title class="d-flex align-items-center ">
                <v-icon
                  size="60px"
                  color="#546E7A"
                >
                  mdi-license
                </v-icon>
              </v-card-title>
            </div>
          </div>
          <div class="d-flex justify-space-between ma-3">
            <v-btn
              color="#FF3700"
              outlined
              width="100%"
              @click="purchaseMore()"
            >
              Purchase More
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        class="d-flex flex-column "
      >
        <v-card
          width="100%"
          height="100%"
          class="border-bottom card-container"
          elevation="2"
        >
          <div class="d-flex pl-4 mb-4 align-center">
            <v-card color="green">
              <v-icon
                size="30px"
                color="white"
                style="padding: 5px;"
              >
                mdi-lan-connect
              </v-icon>
            </v-card>
            <div class="content-container">
              <div class=" light-color ">
                Used Licenses
              </div>
              {{ digiBoardLicenceCounts.used_licenses }}
            </div>
          </div>
        </v-card>
        <v-card
          width="100%"
          height="100%"
          elevation="2"
          class="border-bottom card-container"
        >
          <div class="d-flex pl-4 mb-4 align-center">
            <v-card color="blue">
              <v-icon
                size="30px"
                color="white"
                style="padding: 5px;"
              >
                mdi-clipboard-text-multiple
              </v-icon>
            </v-card>
            <div class="content-container">
              <div class=" light-color ">
                Active Licenses
              </div>
              {{ digiBoardLicenceCounts.active_licenses }}
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        class="d-flex flex-column"
      >
        <v-card
          width="100%"
          height="100%"
          elevation="2"
          class="border-bottom card-container"
        >
          <div class="d-flex pl-4 mb-4 align-center">
            <v-card color="grey">
              <v-icon
                size="30px"
                color="white"
                style="padding: 5px;"
              >
                mdi-timer-sand-complete
              </v-icon>
            </v-card>
            <div class="content-container">
              <div class="light-color">
                Expired Licenses
              </div>
              {{ digiBoardLicenceCounts.expired_licenses }}
            </div>
          </div>
        </v-card>
        <v-card
          width="100%"
          height="100%"
          elevation="2"
          class="border-bottom card-container"
        >
          <div class="d-flex pl-4 mb-4 align-center">
            <v-card color="red">
              <v-icon
                size="30px"
                color="white"
                style="padding: 5px;"
              >
                mdi-close-thick
              </v-icon>
            </v-card>
            <div class="content-container">
              <div class=" light-color ">
                Cancelled Licenses
              </div>
              {{ digiBoardLicenceCounts.cancelled_licenses }}
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import global from 'src/mixins/global';
export default {
  name: 'DigiBoardLicenceCounts',
  mixins: [global],
  computed: {
    digiBoardLicenceCounts () {
      return this.$store.getters['digiboards/getDigiBoardLicenceCounts'] ?? [];
    },
  },
  async mounted () {
    this.permissionCheck('display-licenses') && await this.getDigiBoardLicenceCounts();
  },
  methods: {
    getDigiBoardLicenceCounts () {
      this.$store.dispatch('digiboards/fetchDigiBoardLicenceCounts');
    },
    purchaseMore () {
      this.$router.push({ name: 'Product Payment Plans' });
    },
  },
};
</script>
<style scoped>
.light-color {
  color: #37474F;
  font-size: 13px;
  font-weight: bold;
}
.total-license {
  font-size: 24px;
  font-weight: bold;
  color: #37474F;
}
.card-container {
  display: flex;
  flex-direction: column;
  background-color: #ECEFF1;
  border-bottom: 3px #546E7A solid;
}
.content-container {
  font-size: 20px;
  margin-top: 20px;
  margin-left: 10px;
}
.main-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 3px #546E7A solid;
  background-color: #ECEFF1;
}
.main-card-content {
  font-size: 28px;
  font-weight: bold;
  color: #37474F;
}
</style>
